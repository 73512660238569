// eslint-disable-next-line import/no-named-as-default
import liff from "@line/liff";
import { Button, Stack } from "@mantine/core";
import { useGetSmartReceiptMemberPointCardInquiry } from "api/smart_receipt";
import connected_image from "assets/images/smart_receipt/pilot_connected.png";
import disconnected_image from "assets/images/smart_receipt/pilot_disconnected.png";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";

type SmartReceiptProps = {
  regularMember: boolean;
};

type DisconnectedContentProps = {
  alt: string;
  path: string;
};

const SmartReceiptCard: FC<SmartReceiptProps> = ({ regularMember }) => {
  const {
    data: memberPointCardData,
    error,
    isValidating: isValidatingGetMemberPointCard
  } = useGetSmartReceiptMemberPointCardInquiry({
    revalidateOnFocus: true,
    shouldRetryOnError: false
  });

  // エラーの場合は何も表示しない
  if (isValidatingGetMemberPointCard || error?.status === "failed") {
    return null;
  }

  // 会員でない場合の処理
  if (!regularMember) {
    return (
      <UnConnectedContent
        path="/smart-receipt/guest"
        alt="本会員登録で機能開放"
      />
    );
  }

  // スマートレシートと連携済みの場合
  if (memberPointCardData?.member_flag === true && memberPointCardData.url) {
    return renderLinkedContent(memberPointCardData.url);
  }

  // スマートレシート未連携の場合
  if (memberPointCardData?.member_flag === false) {
    return (
      <UnConnectedContent
        path="/smart-receipt/signup"
        alt="スマートレシートに連携する"
      />
    );
  }

  return null;
};

// スマートレシート連携済みの場合のコンテンツ
const renderLinkedContent = (url: string) => (
  <Stack spacing="xs">
    <a
      href={url}
      onClick={(e) => {
        e.preventDefault();
        liff.openWindow({ external: true, url });
      }}
    >
      <img
        src={connected_image}
        alt="電子レシートの確認をする"
        style={{ width: "100%" }}
      />
    </a>
  </Stack>
);

const UnConnectedContent: FC<DisconnectedContentProps> = ({ path, alt }) => {
  const navigate = useNavigate();

  return (
    <Stack spacing="xs">
      <Button
        p={0}
        styles={{
          root: {
            backgroundColor: "transparent",
            border: "none",
            borderRadius: 0,
            height: "auto",
            padding: 0,
            width: "100%"
          }
        }}
        onClick={() => navigate(path)}
      >
        <img src={disconnected_image} alt={alt} style={{ width: "100%" }} />
      </Button>
    </Stack>
  );
};

export default SmartReceiptCard;
